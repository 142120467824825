var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('view-base',{attrs:{"queries":_vm.queries,"queries-wait":"","skeleton":"card-list-skeleton"},on:{"queries-response":_vm.onQueriesResponse}},[_c('b-alert',{attrs:{"variant":"warning","show":_vm.pendingMigrations}},[_c('icon',{attrs:{"iname":"exclamation-triangle"}}),_vm._v(" "),_c('span',{domProps:{"innerHTML":_vm._s(_vm.$t('pending_migrations'))}})],1),_c('b-alert',{attrs:{"variant":"warning","show":_vm.importantYunohostUpgrade}},[_c('icon',{attrs:{"iname":"exclamation-triangle"}}),_vm._v(" "),_c('span',{domProps:{"innerHTML":_vm._s(_vm.$t('important_yunohost_upgrade'))}})],1),_c('card',{attrs:{"title":_vm.$t('system'),"icon":"server","no-body":""},scopedSlots:_vm._u([(_vm.system)?{key:"buttons",fn:function(){return [_c('b-button',{directives:[{name:"t",rawName:"v-t",value:('system_upgrade_all_packages_btn'),expression:"'system_upgrade_all_packages_btn'"}],attrs:{"variant":"success"},on:{"click":function($event){return _vm.performUpgrade({ type: 'system' })}}})]},proxy:true}:null],null,true)},[(_vm.system)?_c('b-list-group',{attrs:{"flush":""}},_vm._l((_vm.system),function(ref){
var name = ref.name;
var current_version = ref.current_version;
var new_version = ref.new_version;
return _c('b-list-group-item',{key:name},[_c('h5',{staticClass:"m-0"},[_vm._v(" "+_vm._s(name)+" "),_c('small',{staticClass:"text-secondary"},[_vm._v("("+_vm._s(_vm.$t('from_to', [current_version, new_version]))+")")])])])}),1):(_vm.system === null)?_c('b-card-body',[_c('span',{staticClass:"text-success"},[_c('icon',{attrs:{"iname":"check-circle"}}),_vm._v(" "+_vm._s(_vm.$t('system_packages_nothing')))],1)]):_vm._e()],1),_c('card',{attrs:{"title":_vm.$t('applications'),"icon":"cubes","no-body":""},scopedSlots:_vm._u([(_vm.apps)?{key:"buttons",fn:function(){return [_c('b-button',{directives:[{name:"t",rawName:"v-t",value:('system_upgrade_all_applications_btn'),expression:"'system_upgrade_all_applications_btn'"}],attrs:{"variant":"success"},on:{"click":function($event){return _vm.performUpgrade({ type: 'apps' })}}})]},proxy:true}:null],null,true)},[(_vm.apps)?_c('b-list-group',{attrs:{"flush":""}},_vm._l((_vm.apps),function(ref){
var name = ref.name;
var id = ref.id;
var current_version = ref.current_version;
var new_version = ref.new_version;
return _c('b-list-group-item',{key:id,staticClass:"d-flex justify-content-between align-items-center"},[_c('h5',{staticClass:"m-0"},[_vm._v(" "+_vm._s(name)+" "),_c('small',[_vm._v("("+_vm._s(id)+") "+_vm._s(_vm.$t('from_to', [current_version, new_version])))])]),_c('b-button',{directives:[{name:"t",rawName:"v-t",value:('system_upgrade_btn'),expression:"'system_upgrade_btn'"}],attrs:{"variant":"success","size":"sm"},on:{"click":function($event){return _vm.performUpgrade({ type: 'specific_app', id: id })}}})],1)}),1):(_vm.apps === null)?_c('b-card-body',[_c('span',{staticClass:"text-success"},[_c('icon',{attrs:{"iname":"check-circle"}}),_vm._v(" "+_vm._s(_vm.$t('system_apps_nothing')))],1)]):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }